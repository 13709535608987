export default {
  mastercard: 'Mastercard',
  visa: 'VISA',
  amex: 'AMEX',
  maestro: 'Maestro',
  site_search: {
    none: 'Disabled',
    nonnude: 'Non nude profiles only',
    full: 'Full search enabled',
  },

  site_signups: {
    all: 'Open',
    with_trafficsource: 'Only with trafficsource',
    none: 'Only API',
  },
  discountcode_type: {
    admin: 'Created by admin',
    'account-delete': 'Created in account delete flow',
  },
  export_options: {
    export_trafficsource_costs: 'Export trafficsource costs',
    export_only_paid_leads: 'Export only paid leads',
    export_deducted_leads: 'Export deducted leads',
  },
  always: 'Every order',
  'once-per-user': 'Once per user',
  once: 'Once',

  related_tickets: '1 related ticket | {count} related tickets',
  html: 'HTML',
  text: 'text',
  html_translated: 'HTML (translated)',
  text_translated: 'text (translated)',
  creditcard: 'Creditcard',
  ideal: 'iDEAL',
  bancontact: 'Bancontact',
  applepay: 'Apple Pay',
  nexi: 'Nexi (Creditcard)',
  postepay: 'Postepay',
  paysafecard: 'Paysafecard',
  paypal: 'PayPal',
  sdd: 'SEPA Direct Debit',
  sofort: 'SOFORT',
  giropay: 'Giropay',
  sct: 'SEPA Credit Transfer',
  eps: 'EPS',
  alternativepayments: 'Alt Payments',
  paynl: 'Pay.nl',
  rocketgate: 'RocketGate',
  mstream: 'Mstream',
  importflevohost: 'Flevohost',
  dialxs: 'DialXS',
  targetpay: 'TargetPay',
  member_flags: {
    enable_full_history: 'Enable full message history',
    disable_recurring: 'Disable recurring payments',
    disable_creditcard: 'Disable creditcard payments',
    disable_paypal: 'Disable PayPal payments',
    disable_sdd: 'Disable Sepa Direct Debit',
    privacy_mode: 'Privacy mode',
    disable_transaction_costs: 'Disable transaction costs',
  },
  without_region: 'Without region',
  DISCOUNTCODE_ADD: 'Add discountcode',
  DISCOUNTCODE_ADD_USER: 'Add discountcode for {name}',
  additional_message: {
    none: 'None',
    optional_gift: 'Optional gift',
    optional_photo: 'Optional photo',
    forced_gift: 'Forced gift (only when gift mentioned in text)',
    forced_photo: 'Forced photo (only when photo mentioned in text)',
    after_text: 'After text message',
    before_text: 'Before text message',
    profilephoto: 'Profile photo',
    nudity: 'Photo with nudity visible',
    face: 'Photo with face visible',
  },
  pickupline_types: {
    'post-like': 'Reply after user sent like',
    'post-match': 'Reply after like match',
    normal: 'Pickup queue',
  },
  topic_reason: {
    model: 'ML Model',
    model_exact: 'ML Model - exact match',
    site: 'Sex-focussed website',
    photos: 'Nudity in photos',
    manual: 'Manually changed',
  },
  AGE_DISPLAY: '{age} yo',

  breast_size: 'Breast size',
  not_visible: 'Not visible',
  small: 'Small',
  large: 'Large',
  body_type: 'Body type',
  slim: 'Slim',
  normal: 'Normal',
  fat: 'Fat',
  attractiveness: 'Attractiveness',
  ugly: 'Ugly',
  average: 'Gemiddeld',
  beautifyl: 'Beautifyl',
  race: 'Race',
  white: 'White',
  asian: 'Asian',
  latino: 'Latino',
  arab: 'Arab',
  appearance: 'Appearance',
  slut: 'Slutty',
  naked: 'Naked / lingerie',
  Permissions: 'Permissions',
  operator_name: 'Name',
  open_conversations: 'Open conversations',
  no_open_conversations: 'No open conversations / waiting in queue',

  language: 'Language',
  cnt_pokes: '# pokes',
  cnt_messages: '# messages',
  cnt_operators: '# operators',
  oldest_message: 'Oldest message',
  newest_message: 'Latest message',
  email_phone_first_message: 'Email / phone number filtered in first message',
  fictional_profileimage: 'Fictional profile image used',
  active: 'Active',
  disabled: 'Disabled',
  deleted: 'Deleted',
  quarantaine: 'Quarantine',
  no_quarantaine: 'Not quarantined',
  finalized_signup: 'Finalized signup',
  banned: 'Banned',
  anonymized: 'Anonymized',
  changed_flags: 'Updated account settings',
  paid_user: 'Paid member',
  non_paid_user: 'Free member',
  notifications_reactivated: 'Notifications reactivated',
  notifications_disabled: 'Notifications disabled',
  not_confirmed: 'Email not confirmed',
  page_hidden: 'Page hidden',
  api_pending_click: 'API lead - Pending redirect click',
  api_pending_verify: 'API lead - Pending redirect verify',
  api_expired: 'API Lead - Redirect URL expired',
  duplicate_profileimage: 'Duplicate photo',
  duplicate_ip: 'Duplicate IP address',
  duplicate_email: 'Duplicate e-mail address',
  duplicate_email_banned: 'Banned account with same e-mail address',
  duplicate_push: 'Duplicate push subscription',
  missing_useragent: 'Missing user agent, bot?',
  wrong_useragent_hash: 'Wrong user agent hash, bot?',
  multiple_forwarded_for: 'Unexpected X-Forwarded-For Header (Proxy?)',
  timezone_mismatch: 'Timezone does not match country',
  notes_text: 'About me text',
  old_email: 'E-mail of deleted account',
  duplicate_profile: 'Duplicate member profile?',
  duplicate_payer_identifier: 'Duplicate payer identifier (IBAN,CC)',
  asn: 'Untrusted ASN (VPN?, BOT?)',
  tor: 'TOR exitpoint',
  credits_text: 'Credits mentioned in first message',
  akismet: 'Possible SPAM message',
  geoip: 'GEOIP does not match signup country',

  choose_country: 'Select country',
  CHAT_ADMIN_TITLE: 'Chat Admin',
  Members: 'Members',
  Logout: 'Logout',
  Search: 'Search',
  'Search now': 'Search now',
  VIEW_MEMBER: 'View {id} ',
  'Photo approval': 'Photo approval',
  group_by: {
    paymentmethod: 'Payment method',
    paymentprovider: 'Payment provider',
    paymentaccount: 'Payment account',
    agegroup: 'Age group',
    countryregion: 'Region',
    sexuality: 'Sexuality',
    trafficsource: 'Traffic source',
    status: 'Account status',
    sub1: 'Subid 1',
    sub2: 'Subid 2',
    sub3: 'Subid 3',
    signup_path: 'Signup URI',
    team: 'Team',
    type: 'Message type',
    language: 'Language',
    site: 'Site',
    signup_referrer: 'HTTP Referrer',
    country: 'Country',
    'payment-method': 'Payment method',
    browser_name: 'Browser',
    browser_version: 'Browser version',
    os_name: 'OS',
    os_version: 'OS Version',
    device_type: 'Device type',
    mailprovider: 'E-mail provider',
    gender: 'Gender',
  },
  MEMBER_TITLE: 'Member: {name}',
  PROFILE_TITLE: 'Profile: {name}',
  choose_region: 'Select region',
  eye_color: 'Eye color',
  hair_color: 'Hair color',
  relationship_status: 'Relationship status',
};
