
export default {
  name: 'Default',
  data() {
    return {
      drawer: null,
      fetchUserInterval: null,
      checkReleaseInterval: null,
      showReleaseWarning: false,
      visibilityState: 'visible',
      reloadPhotoCountOnVisibilityStateChange: false,
    };
  },
  head() {
    return {
      title: this.$store.state.app_bar_title,
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    };
  },
  computed: {},
  watch: {
    visibilityState(newValue, oldValue) {
      if (oldValue !== newValue) {
        if (
          newValue === 'visible' &&
          this.reloadPhotoCountOnVisibilityStateChange
        ) {
          this.$auth.fetchUser();
          this.$store.dispatch('settings/load_photo_approval_count');

          this.reloadPhotoCountOnVisibilityStateChange = false;
          this.addFetchUserInterval();
        }
      }
    },
  },
  beforeMount() {
    document.addEventListener('visibilitychange', this.visibilityDidChange);
  },

  mounted() {
    this.$store.dispatch('settings/load_photo_approval_count');
    this.$store.dispatch('settings/load_fictional_profile_review_count');
    this.$store.dispatch('settings/load_profile_check_count');
    this.checkRelease();
    if (!this.fetchUserInterval) {
      this.addFetchUserInterval();
    }
    if (!this.checkReleaseInterval) {
      this.checkReleaseInterval = setInterval(() => {
        if (this.visibilityState === 'visible') {
          this.checkRelease();
        }
      }, 30000);
    }
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.visibilityDidChange);

    if (this.fetchUserInterval) {
      clearInterval(this.fetchUserInterval);
      clearInterval(this.checkReleaseInterval);
    }
  },
  methods: {
    addFetchUserInterval() {
      this.fetchUserInterval = setInterval(() => {
        if (this.$auth.loggedIn) {
          if (this.visibilityState === 'visible') {
            this.$auth.fetchUser();
            this.$store.dispatch('settings/load_photo_approval_count');
          } else {
            this.reloadPhotoCountOnVisibilityStateChange = true;
            clearInterval(this.fetchUserInterval);
          }
        }
      }, 60000);
    },
    visibilityDidChange() {
      this.visibilityState = document.visibilityState;
    },

    checkRelease() {
      if (process.env.NODE_ENV === 'production') {
        this.$axios
          .get('/last-release', {
            params: { t: Date.now() },
          })
          .then((res) => {
            this.showReleaseWarning =
              res.data.toString().trim() !==
              process.env.NUXT_ENV_LAST_RELEASE.trim();
          })
          .catch(() => {});
      }
    },
    refreshPage() {
      window.location.reload();
    },
  },
};
