import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4b7e215d = () => interopDefault(import('../pages/admins/index.vue' /* webpackChunkName: "pages/admins/index" */))
const _4e05a808 = () => interopDefault(import('../pages/autopokes/index.vue' /* webpackChunkName: "pages/autopokes/index" */))
const _6c8812d6 = () => interopDefault(import('../pages/autoreplies/index.vue' /* webpackChunkName: "pages/autoreplies/index" */))
const _4f7a04eb = () => interopDefault(import('../pages/datasources/index.vue' /* webpackChunkName: "pages/datasources/index" */))
const _3691d662 = () => interopDefault(import('../pages/discountcodes/index.vue' /* webpackChunkName: "pages/discountcodes/index" */))
const _54c1a703 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _42a22476 = () => interopDefault(import('../pages/members/index.vue' /* webpackChunkName: "pages/members/index" */))
const _a7e699da = () => interopDefault(import('../pages/message-reports.vue' /* webpackChunkName: "pages/message-reports" */))
const _4556291e = () => interopDefault(import('../pages/mlmodel-review.vue' /* webpackChunkName: "pages/mlmodel-review" */))
const _ec5aae9a = () => interopDefault(import('../pages/operator-costs.vue' /* webpackChunkName: "pages/operator-costs" */))
const _0826fea8 = () => interopDefault(import('../pages/operatorteams.vue' /* webpackChunkName: "pages/operatorteams" */))
const _b484b554 = () => interopDefault(import('../pages/pickup-queue.vue' /* webpackChunkName: "pages/pickup-queue" */))
const _5bcac040 = () => interopDefault(import('../pages/pickuplines/index.vue' /* webpackChunkName: "pages/pickuplines/index" */))
const _7e925872 = () => interopDefault(import('../pages/profile-images.vue' /* webpackChunkName: "pages/profile-images" */))
const _5b965d6b = () => interopDefault(import('../pages/quarantaine.vue' /* webpackChunkName: "pages/quarantaine" */))
const _817847ce = () => interopDefault(import('../pages/regions.vue' /* webpackChunkName: "pages/regions" */))
const _b3efdc94 = () => interopDefault(import('../pages/revenue.vue' /* webpackChunkName: "pages/revenue" */))
const _0f3bf990 = () => interopDefault(import('../pages/signup-blocks/index.vue' /* webpackChunkName: "pages/signup-blocks/index" */))
const _1965ebae = () => interopDefault(import('../pages/sites/index.vue' /* webpackChunkName: "pages/sites/index" */))
const _d2be4c94 = () => interopDefault(import('../pages/sms/index.vue' /* webpackChunkName: "pages/sms/index" */))
const _aeebc56e = () => interopDefault(import('../pages/tfa.vue' /* webpackChunkName: "pages/tfa" */))
const _241dd3ec = () => interopDefault(import('../pages/tickettemplates/index.vue' /* webpackChunkName: "pages/tickettemplates/index" */))
const _45a84056 = () => interopDefault(import('../pages/trafficsource-performance.vue' /* webpackChunkName: "pages/trafficsource-performance" */))
const _304f4298 = () => interopDefault(import('../pages/trafficsources/index.vue' /* webpackChunkName: "pages/trafficsources/index" */))
const _79670b8a = () => interopDefault(import('../pages/translations/index.vue' /* webpackChunkName: "pages/translations/index" */))
const _61131a5e = () => interopDefault(import('../pages/userpayments/index.vue' /* webpackChunkName: "pages/userpayments/index" */))
const _3b978600 = () => interopDefault(import('../pages/autoreplies/firstmessages.vue' /* webpackChunkName: "pages/autoreplies/firstmessages" */))
const _03aa740b = () => interopDefault(import('../pages/members/highrollers.vue' /* webpackChunkName: "pages/members/highrollers" */))
const _a9f4bca0 = () => interopDefault(import('../pages/profiles/fictional.vue' /* webpackChunkName: "pages/profiles/fictional" */))
const _7e700fc5 = () => interopDefault(import('../pages/profiles/fictional-review.vue' /* webpackChunkName: "pages/profiles/fictional-review" */))
const _c271d792 = () => interopDefault(import('../pages/profiles/review.vue' /* webpackChunkName: "pages/profiles/review" */))
const _08ed43a9 = () => interopDefault(import('../pages/profilesets/profile-check.vue' /* webpackChunkName: "pages/profilesets/profile-check" */))
const _5224944a = () => interopDefault(import('../pages/signup-blocks/add/index.vue' /* webpackChunkName: "pages/signup-blocks/add/index" */))
const _2cfdbe38 = () => interopDefault(import('../pages/sites/add.vue' /* webpackChunkName: "pages/sites/add" */))
const _56aa8fbf = () => interopDefault(import('../pages/tickets/empty.vue' /* webpackChunkName: "pages/tickets/empty" */))
const _1e1c7ce7 = () => interopDefault(import('../pages/discountcodes/add/_userid.vue' /* webpackChunkName: "pages/discountcodes/add/_userid" */))
const _5beed705 = () => interopDefault(import('../pages/admins/_id.vue' /* webpackChunkName: "pages/admins/_id" */))
const _7a3b7ef3 = () => interopDefault(import('../pages/autopokes/_id/index.vue' /* webpackChunkName: "pages/autopokes/_id/index" */))
const _68cfd2c1 = () => interopDefault(import('../pages/autoreplies/_id/index.vue' /* webpackChunkName: "pages/autoreplies/_id/index" */))
const _82be9b54 = () => interopDefault(import('../pages/datasources/_id/index.vue' /* webpackChunkName: "pages/datasources/_id/index" */))
const _cb6fd73e = () => interopDefault(import('../pages/members/_id/index.vue' /* webpackChunkName: "pages/members/_id/index" */))
const _78e0cacb = () => interopDefault(import('../pages/pickuplines/_id/index.vue' /* webpackChunkName: "pages/pickuplines/_id/index" */))
const _7126a8ae = () => interopDefault(import('../pages/profiles/_id/index.vue' /* webpackChunkName: "pages/profiles/_id/index" */))
const _ea5a2ed8 = () => interopDefault(import('../pages/sites/_id/index.vue' /* webpackChunkName: "pages/sites/_id/index" */))
const _08e5d19e = () => interopDefault(import('../pages/sms/_id.vue' /* webpackChunkName: "pages/sms/_id" */))
const _e13567a8 = () => interopDefault(import('../pages/tickets/_id.vue' /* webpackChunkName: "pages/tickets/_id" */))
const _3adc1bf5 = () => interopDefault(import('../pages/tickettemplates/_id/index.vue' /* webpackChunkName: "pages/tickettemplates/_id/index" */))
const _2d8a00c2 = () => interopDefault(import('../pages/trafficsources/_id/index.vue' /* webpackChunkName: "pages/trafficsources/_id/index" */))
const _38be266f = () => interopDefault(import('../pages/datasources/_id/batches/index.vue' /* webpackChunkName: "pages/datasources/_id/batches/index" */))
const _be33385e = () => interopDefault(import('../pages/members/_id/new-conversation.vue' /* webpackChunkName: "pages/members/_id/new-conversation" */))
const _0e5e5b68 = () => interopDefault(import('../pages/trafficsources/_id/blocks/index.vue' /* webpackChunkName: "pages/trafficsources/_id/blocks/index" */))
const _24e2f356 = () => interopDefault(import('../pages/trafficsources/_id/costs/index.vue' /* webpackChunkName: "pages/trafficsources/_id/costs/index" */))
const _b2bd5698 = () => interopDefault(import('../pages/datasources/_id/batches/upload.vue' /* webpackChunkName: "pages/datasources/_id/batches/upload" */))
const _7fe999ca = () => interopDefault(import('../pages/trafficsources/_id/blocks/add.vue' /* webpackChunkName: "pages/trafficsources/_id/blocks/add" */))
const _75d5906a = () => interopDefault(import('../pages/trafficsources/_id/costs/_costid.vue' /* webpackChunkName: "pages/trafficsources/_id/costs/_costid" */))
const _e9cbf828 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admins",
    component: _4b7e215d,
    name: "admins"
  }, {
    path: "/autopokes",
    component: _4e05a808,
    name: "autopokes"
  }, {
    path: "/autoreplies",
    component: _6c8812d6,
    name: "autoreplies"
  }, {
    path: "/datasources",
    component: _4f7a04eb,
    name: "datasources"
  }, {
    path: "/discountcodes",
    component: _3691d662,
    name: "discountcodes"
  }, {
    path: "/login",
    component: _54c1a703,
    name: "login"
  }, {
    path: "/members",
    component: _42a22476,
    name: "members"
  }, {
    path: "/message-reports",
    component: _a7e699da,
    name: "message-reports"
  }, {
    path: "/mlmodel-review",
    component: _4556291e,
    name: "mlmodel-review"
  }, {
    path: "/operator-costs",
    component: _ec5aae9a,
    name: "operator-costs"
  }, {
    path: "/operatorteams",
    component: _0826fea8,
    name: "operatorteams"
  }, {
    path: "/pickup-queue",
    component: _b484b554,
    name: "pickup-queue"
  }, {
    path: "/pickuplines",
    component: _5bcac040,
    name: "pickuplines"
  }, {
    path: "/profile-images",
    component: _7e925872,
    name: "profile-images"
  }, {
    path: "/quarantaine",
    component: _5b965d6b,
    name: "quarantaine"
  }, {
    path: "/regions",
    component: _817847ce,
    name: "regions"
  }, {
    path: "/revenue",
    component: _b3efdc94,
    name: "revenue"
  }, {
    path: "/signup-blocks",
    component: _0f3bf990,
    name: "signup-blocks"
  }, {
    path: "/sites",
    component: _1965ebae,
    name: "sites"
  }, {
    path: "/sms",
    component: _d2be4c94,
    name: "sms"
  }, {
    path: "/tfa",
    component: _aeebc56e,
    name: "tfa"
  }, {
    path: "/tickettemplates",
    component: _241dd3ec,
    name: "tickettemplates"
  }, {
    path: "/trafficsource-performance",
    component: _45a84056,
    name: "trafficsource-performance"
  }, {
    path: "/trafficsources",
    component: _304f4298,
    name: "trafficsources"
  }, {
    path: "/translations",
    component: _79670b8a,
    name: "translations"
  }, {
    path: "/userpayments",
    component: _61131a5e,
    name: "userpayments"
  }, {
    path: "/autoreplies/firstmessages",
    component: _3b978600,
    name: "autoreplies-firstmessages"
  }, {
    path: "/members/highrollers",
    component: _03aa740b,
    name: "members-highrollers"
  }, {
    path: "/profiles/fictional",
    component: _a9f4bca0,
    name: "profiles-fictional"
  }, {
    path: "/profiles/fictional-review",
    component: _7e700fc5,
    name: "profiles-fictional-review"
  }, {
    path: "/profiles/review",
    component: _c271d792,
    name: "profiles-review"
  }, {
    path: "/profilesets/profile-check",
    component: _08ed43a9,
    name: "profilesets-profile-check"
  }, {
    path: "/signup-blocks/add",
    component: _5224944a,
    name: "signup-blocks-add"
  }, {
    path: "/sites/add",
    component: _2cfdbe38,
    name: "sites-add"
  }, {
    path: "/tickets/empty",
    component: _56aa8fbf,
    name: "tickets-empty"
  }, {
    path: "/discountcodes/add/:userid?",
    component: _1e1c7ce7,
    name: "discountcodes-add-userid"
  }, {
    path: "/admins/:id",
    component: _5beed705,
    name: "admins-id"
  }, {
    path: "/autopokes/:id",
    component: _7a3b7ef3,
    name: "autopokes-id"
  }, {
    path: "/autoreplies/:id",
    component: _68cfd2c1,
    name: "autoreplies-id"
  }, {
    path: "/datasources/:id",
    component: _82be9b54,
    name: "datasources-id"
  }, {
    path: "/members/:id",
    component: _cb6fd73e,
    name: "members-id"
  }, {
    path: "/pickuplines/:id",
    component: _78e0cacb,
    name: "pickuplines-id"
  }, {
    path: "/profiles/:id",
    component: _7126a8ae,
    name: "profiles-id"
  }, {
    path: "/sites/:id",
    component: _ea5a2ed8,
    name: "sites-id"
  }, {
    path: "/sms/:id",
    component: _08e5d19e,
    name: "sms-id"
  }, {
    path: "/tickets/:id?",
    component: _e13567a8,
    name: "tickets-id"
  }, {
    path: "/tickettemplates/:id",
    component: _3adc1bf5,
    name: "tickettemplates-id"
  }, {
    path: "/trafficsources/:id",
    component: _2d8a00c2,
    name: "trafficsources-id"
  }, {
    path: "/datasources/:id/batches",
    component: _38be266f,
    name: "datasources-id-batches"
  }, {
    path: "/members/:id/new-conversation",
    component: _be33385e,
    name: "members-id-new-conversation"
  }, {
    path: "/trafficsources/:id/blocks",
    component: _0e5e5b68,
    name: "trafficsources-id-blocks"
  }, {
    path: "/trafficsources/:id/costs",
    component: _24e2f356,
    name: "trafficsources-id-costs"
  }, {
    path: "/datasources/:id/batches/upload",
    component: _b2bd5698,
    name: "datasources-id-batches-upload"
  }, {
    path: "/trafficsources/:id/blocks/add",
    component: _7fe999ca,
    name: "trafficsources-id-blocks-add"
  }, {
    path: "/trafficsources/:id/costs/:costid",
    component: _75d5906a,
    name: "trafficsources-id-costs-costid"
  }, {
    path: "/",
    component: _e9cbf828,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
